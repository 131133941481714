html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, textarea,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
}
html {
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  html {
    scroll-margin-top: 100px;
  }
}

@media (max-width: 1024px) {
  html {
    scroll-margin-top: 60px;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input:focus, textarea {
  outline: 0;
}
input, textarea {
  background-color: rgba(0,0,0,0);
  background-clip: border-box;
  border-color: rgba(0,0,0,0);
}

/* button */
button {
  box-sizing: content-box;
}
button {
  background: none;
  border: none;
  color: inherit;
  cursor: default;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  -ms-appearance: button;
  appearance: button;
  user-select: none;
  border-radius: 0;
}
button::focus-inner {
  border: 0;
  padding: 0;
}
button:focus {
  outline:0;
}
@font-face {
  font-family: TTNorms;
  font-weight: normal;
  font-style: normal;
  src: url(../fonts/TTNorms.woff2) format('woff2');
}
@font-face {
  font-family: TTNorms;
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/TTNorms-Bold.woff2) format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: normal;
  font-style: normal;
  src: url(../fonts/Larsseit.woff2) format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 600;
  font-style: normal;
  src: url(../fonts/Larsseit-Bold.woff2) format('woff2');
}
@font-face {
  font-family: Larsseit;
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/Larsseit-ExtraBold.woff2) format('woff2');
}
@font-face {
  font-family: Sailec;
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/Sailec-Regular.woff2) format('woff2');
}
@font-face {
  font-family: Sailec;
  font-weight: 600;
  font-style: normal;
  src: url(../fonts/Sailec-Medium.woff2) format('woff2');
}
